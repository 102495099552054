<template>
  <div>
    <div>
      <template>
        <a-card v-if="this.viewHasRight === true" style="margin-bottom: 24px">
          <div class="table-operator" style="margin-bottom: -8px">
            <a-form layout="inline">
              <a-row :gutter="5" style="line-height: 3.5">
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店编号">
                    <a-input
                      v-model="queryParam.code"
                      placeholder="请输入"
                      @pressEnter="select"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店状态">
                    <a-select
                      style="width: 175px"
                      v-model="queryParam.status"
                      @change="select"
                      allowClear
                      placeholder="请选择"
                    >
                      <a-select-option
                        v-for="item in storeStatus"
                        :key="item.id"
                      >
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店类型">
                    <a-select
                      style="width: 175px"
                      v-model="queryParam.type"
                      @change="select"
                      allowClear
                      placeholder="请选择"
                    >
                      <a-select-option v-for="item in storeType" :key="item.id">
                        {{ item.name }}
                      </a-select-option>
                    </a-select>
                  </a-form-item>
                </a-col>
                <a-col :md="8" :sm="24">
                  <a-form-item label="门店名称">
                    <a-input
                      style="overflow: hidden; text-overflow: ellipsis"
                      v-model="queryParam.name"
                      @pressEnter="select"
                      placeholder="请输入"
                    />
                  </a-form-item>
                </a-col>
                <a-col :md="(!advanced && 8) || 24" :sm="24">
                  <span
                    class="table-page-search-submitButtons"
                    :style="
                      (advanced && { float: 'right', overflow: 'hidden' }) || {}
                    "
                  >
                    <!--                    <a-button type="primary" @click="select">查询</a-button>-->
                    <a-button
                      style="margin-left: 8px"
                      @click="() => ((queryParam = {}), this.init())"
                      >重置</a-button
                    >
                  </span>
                </a-col>
              </a-row>
            </a-form>
          </div>
        </a-card>
        <a-card>
<!--          <div class="table-operator">
            <a-button
              v-if="this.newHasRight === true"
              type="primary"
              icon="plus"
              @click="addStore"
              >新建</a-button
            >
          </div>-->
          <a-table
            rowKey="id"
            :pagination="pagination"
            :columns="columns"
            :dataSource="data"
            :scroll="{ x: 1200 }"
            @change="changePageSize"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
            <span
              slot="status"
              slot-scope="text, record"
              :class="
                record.status == '已签约'
                  ? 'yellow'
                  : record.status == '施工中'
                  ? 'orange'
                  : record.status == '已开业'
                  ? 'green'
                  : record.status == '停业中'
                  ? 'red'
                  : 'gray'
              "
            >
              {{ record.status }}
            </span>
            <span slot="action" slot-scope="text, record" class="blueColor">
              <template>
                <a v-if="viewHasRight === true" @click="handleDetails(record)"
                  >查看</a
                >&nbsp;&nbsp;
<!--                <a v-if="editHasRight === true" @click="handleEdit(record)"
                  >编辑</a>-->
                &nbsp;&nbsp;
                <a @click="handleActive(record)">操作日志</a>
              </template>
            </span>
          </a-table>
        </a-card>
        <a-modal
          title="操作日志"
          width="700px"
          v-model="visible"
          @ok="handleOk"
          :okButtonProps="{ props: { disabled: true } }"
          :cancelButtonProps="{ props: { disabled: true } }"
          footer
          @change="changePageActionSize"
        >
          <a-table
            rowKey="id"
            :pagination="paginationaction"
            :columns="actioncolumns"
            :dataSource="actiondata"
          >
            <span slot="serial" slot-scope="text, record, index">
              {{ index + 1 }}
            </span>
          </a-table>
        </a-modal>
      </template>
    </div>
  </div>
</template>

<script>
  
import axios from "axios";
import icon1 from "@/assets/icon1.png";
import icon2 from "@/assets/icon2.png";
import icon3 from "@/assets/icon3.png";
import Bus from "@/bus";
import moment from "moment";
import { defaultPagination } from "@/utils/pagination";
import { mapGetters } from "vuex";
import { FacilityActions } from "../../utils/actions";
import { hasPermissions } from "../../utils/auth";

const datastore = [];
const storeProjects = [
  {
    title: "已签约",
    description: "999",
    cover: "@<%= BASE_URL %>favicon.png"
  },
  {
    title: "施工中",
    description: "25"
  },
  {
    title: "已开业",
    description: "8"
  },
  {
    title: "停业中",
    description: "90%"
  },
  {
    title: "已关店",
    description: "25"
  },
  {
    title: "招租率",
    description: "25%"
  }
];
const roleProjectsLeft = [
  {
    title: "总客户数",
    description: "999",
    cover: "@<%= BASE_URL %>favicon.png"
  },
  {
    title: "中小客户",
    description: "25"
  },
  {
    title: "战略客户",
    description: "8"
  }
];
const roleProjectsRight = [
  {
    title: "老客户",
    description: "999",
    cover: "@<%= BASE_URL %>favicon.png"
  },
  {
    title: "新客户",
    description: "25"
  }
];
const kitchenProjects = [
  {
    title: "总档口数",
    description: "999",
    cover: "https://gw.alipayobjects.com/zos/rmsportal/ComBAopevLwENQdKWiIn.png"
  },
  {
    title: "可招商档口数",
    description: "25"
  },
  {
    title: "空铺数",
    description: "8"
  },
  {
    title: "招租率",
    description: "90%"
  },
  {
    title: "已入驻",
    description: "25"
  },
  {
    title: "预订签约中",
    description: "25%"
  },
  {
    title: "改造中",
    description: "25%"
  },
  {
    title: "退租中",
    description: "125"
  }
];
export default {
  name: "storeList",
  data() {
    return {
      icon1,
      icon2,
      icon3,
      detail: 1,
      visible: false,
      storeDetail: false,
      storeEdit: false,
      storeNew: false,
      total: "",
      storeProjects,
      kitchenProjects,
      roleProjectsLeft,
      roleProjectsRight,
      datastore,
      data: [],
      advanced: false,
      queryParam: {
        name: "",
        type: undefined,
        code: "",
        status: undefined
      },
      actiondata: [],
      paginationaction: defaultPagination(() => {}),
      pagination: defaultPagination(() => {}),
      //表头
      columns: [
        {
          title: "门店名称",
          dataIndex: "name",
          key: "name",
          width: 160,
          fixed: "left"
        },
        {
          title: "门店编号",
          dataIndex: "code",
          key: "code",
          align: "center"
        },

        {
          title: "档口总数",
          dataIndex: "kitchenCount",
          key: "kitchenCount",
          align: "center"
        },
        {
          title: "可招商档口数",
          dataIndex: "emptyKitchenCount",
          key: "emptyKitchenCount",
          align: "center"
        },
        {
          title: "计租率",
          dataIndex: "contractFullRate",
          key: "contractFullRate",
          align: "center"
        },
        {
          title: "门店状态",
          // dataIndex: "status",
          key: "status",
          scopedSlots: { customRender: "status" },
          align: "center"
        },
        {
          title: "门店类型",
          dataIndex: "typeText",
          key: "typeText",
          align: "center"
        },
        {
          title: "城市",
          dataIndex: "cityText",
          key: "cityText",
          align: "center"
        },
        {
          title: "操作",
          key: "action",
          width: 200,
          scopedSlots: { customRender: "action" },
          fixed: "right"
        }
      ],
      //actioncolumns
      actioncolumns: [
        {
          title: "操作人",
          dataIndex: "operator",
          key: "operator"
        },
        {
          title: "操作时间",
          dataIndex: "operationTime",
          key: "operationTime",
          width: "130px"
        },
        {
          title: "操作内容",
          dataIndex: "content",
          key: "content"
        }
      ],
      actionId: "",
      editDetail: "",
      thisDetail: "",
      //门店状态
      storeStatus: [
        {
          id: 1,
          name: "已签约"
        },
        {
          id: 2,
          name: "施工中"
        },
        {
          id: 3,
          name: "已开业"
        },
        {
          id: 4,
          name: "停业中"
        },
        {
          id: 5,
          name: "已关店"
        }
      ],
      //门店类型
      storeType: [
        {
          id: 1,
          name: "纯外卖"
        },
        {
          id: 2,
          name: "外卖+堂食"
        },
        {
          id: 3,
          name: "外卖+大餐厅商户"
        },
        {
          id: 4,
          name: "外卖+高端食堂"
        },
        {
          id: 5,
          name: "外卖+食堂"
        },
        {
          id: 6,
          name: "其他"
        }
      ]
    };
  },
  created() {
    this.init();
    Bus.$on("getTarget", target => {
      this.init();
    });
  },
  computed: {
    ...mapGetters("users", ["authenticatedUser"]),
    newHasRight() {
      return hasPermissions(
        [FacilityActions.FACILITY_CREATE],
        this.authenticatedUser.actions
      );
    },
    viewHasRight() {
      return hasPermissions(
        [FacilityActions.FACILITY_READ],
        this.authenticatedUser.actions
      );
    },
    editHasRight() {
      return hasPermissions(
        [FacilityActions.FACILITY_EDIT],
        this.authenticatedUser.actions
      );
    }
  },
  methods: {
    moment,
    changePageSize(val) {
      this.init(val.current, val.pageSize);
    },
    init(current, pageSize) {
      axios({
        url:   this.$baseUrl + "facility/list",
        method: "GET",
        withCredentials: true,
        params: {
          rows: pageSize || 20,
          page: current || 1,
          ...this.queryParam
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            this.storeStatus1(item);
          });
          this.data = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.pagination = pagination;
        }
      });
    },
    select() {
      this.init();
    },
    storeStatus1(val) {
      // 门店状态
      switch (val.status) {
        case 1:
          val.status = "已签约";
          break;
        case 2:
          val.status = "施工中";
          break;
        case 3:
          val.status = "已开业";
          break;
        case 4:
          val.status = "停业中";
          break;
        case 5:
          val.status = "已关店";
          break;
        default:
        // val.status = "无状态";
      }
      // 出租率
      val.rentalRate = (val.rentalRate * 100).toFixed(1) + "%";
    },
    handleOk() {},
    handleActive(val) {
      this.actionId = val;
      this.handleActivecha();
      this.visible = true;
    },
    handleActivecha(current, pageSize) {
      this.actiondata = [];
      axios({
        url:   this.$baseUrl + "facility/listFacilityLog",
        method: "GET",
        params: {
          rows: pageSize || 20,
          page: current || 1,
          facilityId: this.actionId.id
        }
      }).then(res => {
        if (res.data.rows) {
          res.data.rows.forEach(item => {
            if (item.operationTime) {
              item.operationTime = moment(item.operationTime).format(
                "YYYY-MM-DD"
              );
            }
          });
          this.actiondata = res.data.rows;
          const pagination = { ...this.pagination };
          pagination.total = res.data.count;
          this.paginationaction = pagination;
        }
      });
    },
    changePageActionSize(val) {
      this.handleActivecha(val.current, val.pageSize);
    },
    handleDetails(val) {
      this.$router.push({
        path: "/store/storeList/storeListDetail",
        query: {
          thisDetailId: val.id,
        }
      });
    },
    handleEdit(val) {
      this.$router.push({
        path: "/store/storeList/storeListEdit",
        query: { editDetailId: val.id }
      });
    },
    addStore() {
      this.$router.push({
        path: "/store/storeList/storeListNew"
      });
    }
  }
};
</script>

<style scoped>
.ant-collapse-borderless > .ant-collapse-item > .ant-collapse-content {
  background-color: #1890ff !important;
}

.project-list ant-card ant-card-wider-padding {
  margin: 24px 32px !important;
}
.table-operator {
  margin-bottom: 18px;
}
.blueColor > a {
  color: #1890ff !important;
}
</style>
